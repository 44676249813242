import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/stable'
import 'antd/dist/reset.css'
import './index.css'
import 'regenerator-runtime/runtime'
import React from 'react'
import initializeSentry from 'util/sentry'
import { createRoot } from 'react-dom/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import AppWithProviders from './components/app/AppWithProviders'

initializeSentry()

// https://day.js.org/docs/en/plugin/utc
// https://day.js.org/docs/en/plugin/loading-into-nodejs
// Needs to be called, to use .utc to parse dates
dayjs.extend(utc)
dayjs.extend(timezone)

// https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
const container = document.getElementById('app')
const root = createRoot(container)
root.render(<AppWithProviders />)
