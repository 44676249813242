import dayjs from 'dayjs'
import { STATUS_AVAILABLE } from './TSConstants'

/**
 * @param {Object} timeSlot time slot
 * @param {string} ianaTimezone E.g. "Europe/Brusssels"
 * @returns {boolean} returns if slot has passed
 */
export function checkIfSlotHasPassed(timeSlot, ianaTimezone) {
    // TODO: we should really parse and cache the timeslot date time once instead of every time this function gets called
    // TODO: currently, this function is called way too often -> we should be able to just check this once during setup for each timeslot
    const parsedStart = dayjs.tz(timeSlot.startDateTime, ianaTimezone)
    const nowInTz = dayjs.tz(undefined, ianaTimezone)

    // A log.. for your own sanity.. thank me later
    // console.log("Comparison:", {
    //     ianaTimezone: ianaTimezone,
    //     timeSlotStartDateTime: timeSlot.startDateTime,
    //     parsedStart: parsedStart.format(),
    //     nowInTz: nowInTz.format(),
    //     hasPassed: nowInTz.isAfter(parsedStart),
    // })
    const hasPassed = nowInTz.isAfter(parsedStart)

    // console.log("Has passed:", hasPassed)
    return hasPassed
    // return now > dateTimeOfSlot
}

/**
 *
 * @param {Object} roomAndTimeSlots object with 1 room and all its time slots
 * @returns new object with only slots between bookable hours that have passed
 */
export function getRoomAndTimeSlotsBetweenBookableHours(roomAndTimeSlots) {
    const {
        uiSlots,
        bookableHours,
        date,
        venueTimezone,
        ...remainingProps
    } = roomAndTimeSlots

    // filter slots between bookable hours
    const slotsBetweenBookableHours = uiSlots.filter((uiSlot) => {
        return uiSlot.slot.roomStart < bookableHours.end
            && uiSlot.slot.roomStart >= bookableHours.start
            && checkIfSlotHasPassed(uiSlot.slot, venueTimezone) === false
    })

    // make new roomAndTimeSlots object with only slots between bookable hours
    const roomAndSlotsBetweenBookableHours = {
        uiSlots: slotsBetweenBookableHours,
        ...remainingProps,
    }

    return roomAndSlotsBetweenBookableHours
}

/**
 *
 * @param {Array} roomsAndTimeSlots array of room and timeslots objects
 * @returns array of roomAndTimeSlots objects with only slots between bookable hours
 */
export function getRoomsAndTimeSlotsBetweenBookableHours(roomsAndTimeSlots) {
    // array that holds all rooms and slots between bookable hours
    // get room for each room in roomsAndTimeSlots
    const roomsAndTimeSlotsBetweenBookableHours = roomsAndTimeSlots.map(roomsAndTimeSlots => getRoomAndTimeSlotsBetweenBookableHours(roomsAndTimeSlots))
    return roomsAndTimeSlotsBetweenBookableHours
}

/**
 *
 * @param {Object} roomAndTimeSlots object with 1 room and all its time slots
 * @returns new object with only slots between bookable hours and pattern slots
 */

export function getRoomAndPatternSlotsBetweenBookableHours(roomAndTimeSlots) {
    const { uiSlots, bookableHours, date, ...remainingProps } = roomAndTimeSlots
    const parsedDate = dayjs.utc(date)

    // array that holds all slots in pattern
    const patternSlots = []

    for (let i = 0; i < uiSlots.length; i++) {
        const uiSlot = uiSlots[i]

        // push every fourth slot to patternSlots that are available and between bookable hours
        if (
            uiSlot.slot.roomStart < bookableHours.end
            && uiSlot.slot.roomStart >= bookableHours.start
            && !checkIfSlotHasPassed(uiSlot.slot)
        ) {
            if (uiSlot.slot.status === STATUS_AVAILABLE) {
                // push every fourth slot to patternSlots
                if ((i + 1) % 4 === 0) {
                    patternSlots.push(uiSlot)
                }
            }
        }
    }

    // make new roomAndTimeSlots object with only slots between bookable hours and pattern slots
    const roomAndSlotsPatternBetweenBookableHours = {
        bookableHours,
        uiSlots: patternSlots,
        ...remainingProps,
    }

    return roomAndSlotsPatternBetweenBookableHours
}

/**
 *
 * @param {Array} roomsAndTimeSlots array of room and timeslots objects
 * @returns array with roomsAndTimeSlots object with only slots between bookable hours and pattern slots
 */
export function getRoomsAndTimeSlotsPatternBetweenBookableHours(roomsAndTimeSlots) {
    // array that holds all rooms and pattern slots between bookable hours
    const roomsAndPatternTimeSlotsBetweenBookableHours = []

    // get room for each room in roomsAndTimeSlots
    roomsAndTimeSlots.forEach((roomAndTimeSlots) => {
        // get object roomAndTimeSlot object with pattern between bookable hours
        const roomAndPatternTimeSlotsBetweenBookableHours = getRoomAndPatternSlotsBetweenBookableHours(
            roomAndTimeSlots,
        )
        roomsAndPatternTimeSlotsBetweenBookableHours.push(roomAndPatternTimeSlotsBetweenBookableHours)
    })

    return roomsAndPatternTimeSlotsBetweenBookableHours
}

/**
 * Selects all pattern slots in room
 * @param {Object} roomAndTimeSlots object with 1 room and all its time slots
 * @param {boolean} selectOption to set selected status of slots to true or false
 * @param {Callback} updateSelectedStatusOfSlot function to update selected status of given slots
 */
export function selectPatternSlotsInRoom(
    roomAndTimeSlots,
    selectOption,
    updateSelectedStatusOfSlot,
) {
    // get copy object of roomAndTimeSlots with pattern slots
    const roomAndSlotsPatternBetweenBookableHours = getRoomAndPatternSlotsBetweenBookableHours(
        roomAndTimeSlots,
    )

    if (selectOption === true) {
        updateSelectedStatusOfSlot([roomAndSlotsPatternBetweenBookableHours], true, true)
    } else {
        updateSelectedStatusOfSlot([roomAndSlotsPatternBetweenBookableHours], false, false)
    }
}

/**
 * Selects all slots in room
 * @param {Array} roomAndTimeSlots array of room and timeslots object
 * @param {boolean} selectOption to set selected status of slots to true or false
 * @param {Callback} updateSelectedStatusOfSlot function to update selected status of given slots
 */
export function selectAllSlotsInRoom(
    roomAndTimeSlots,
    selectOption,
    updateSelectedStatusOfSlot,
) {
    // get copy object of roomAndTimeSlots with slots between bookable hours
    const roomAndTimeSlotsBetweenBookableHours = getRoomAndTimeSlotsBetweenBookableHours(
        roomAndTimeSlots,
    )

    if (selectOption === true) {
        updateSelectedStatusOfSlot([roomAndTimeSlotsBetweenBookableHours], true, true)
    } else {
        updateSelectedStatusOfSlot([roomAndTimeSlots], false, false)
    }
}

/**
 * Selects all pattern slots in multiple rooms
 * @param {Array} roomsAndTimeSlots array of room and timeslots object
 * @param {boolean} selectOption to set selected status of slots to true or false
 * @param {Callback} updateSelectedStatusOfSlot function to update selected status of given slots
 */
export function selectAllPatternSlots(
    roomsAndTimeSlots,
    selectOption,
    updateSelectedStatusOfSlot,
) {
    const roomsAndPatternTimeSlots = getRoomsAndTimeSlotsPatternBetweenBookableHours(
        roomsAndTimeSlots,
    )

    if (selectOption === true) {
        updateSelectedStatusOfSlot(roomsAndPatternTimeSlots, true, true)
    } else {
        updateSelectedStatusOfSlot(roomsAndPatternTimeSlots, false, false)
    }
}

/**
 * Selects all pattern slots in multiple rooms
 * @param {Array} roomsAndTimeSlots array of room and timeslots object
 * @param {boolean} selectOption to set selected status of slots to true or false
 * @param {Callback} updateSelectedStatusOfSlot function to update selected status of given slots
 */
export function selectAllSlots(
    roomsAndTimeSlots,
    selectOption,
    updateSelectedStatusOfSlot,
) {
    const roomsAndTimeSlotsBetweenBookableHours = getRoomsAndTimeSlotsBetweenBookableHours(
        roomsAndTimeSlots,
    )

    if (selectOption === true) {
        updateSelectedStatusOfSlot(roomsAndTimeSlotsBetweenBookableHours, true, true)
    } else {
        updateSelectedStatusOfSlot(roomsAndTimeSlotsBetweenBookableHours, false, false)
    }
}

// check if roomsAndTimeSlots has slots outside bookable hours
export function hasSlotsOutsideBookableHours(roomsAndTimeSlots) {
    let slotsOutsideBookableHours = false

    roomsAndTimeSlots.forEach((roomAndTimeSlots) => {
        const { uiSlots, bookableHours } = roomAndTimeSlots

        uiSlots.forEach((uiSlot) => {
            const { slot } = uiSlot

            if (slot.roomStart < bookableHours.start || slot.roomStart >= bookableHours.end) {
                slotsOutsideBookableHours = true
            }
        })
    })

    return slotsOutsideBookableHours
}
